import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const routes = [{
  path: "/",
  redirect: "/index"
}, {
  path: '/index',
  name: 'index',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/gift/index.vue')
}, {
  path: '/detail/:id',
  name: 'detail',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/gift/detail.vue')
}, {
  path: '/login',
  name: 'login',
  component: () => import('../views/login/index.vue')
}, {
  path: '/login/coupon',
  name: 'loginCoupon',
  component: () => import('../views/login/coupon.vue')
}, {
  path: '/certification',
  name: 'certification',
  component: () => import('../views/login/certification.vue')
}, {
  path: '/exchange/:id',
  name: 'exchange',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/gift/exchange.vue')
}, {
  path: '/success',
  name: 'success',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/gift/success.vue')
}, {
  path: '/mine',
  name: 'mine',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/mine/index.vue')
}, {
  path: '/shop',
  name: 'shop',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/index.vue')
}, {
  path: '/goodDetail/:id',
  name: 'goodDetail',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/goodDetail.vue')
}, {
  path: '/orderAdd/:id',
  name: 'orderAdd',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/orderAdd.vue')
}, {
  path: '/orderSuccess',
  name: 'orderSuccess',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/orderSuccess.vue')
}, {
  path: '/orderList',
  name: 'orderList',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/orderList.vue')
}, {
  path: '/orderDetail/:orderType/:id',
  name: 'orderDetail',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/orderDetail.vue')
}, {
  path: '/integralRecord',
  name: 'integralRecord',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/shop/integralRecord.vue')
}, {
  path: '/integral/index',
  name: 'integralIndex',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/integral/index.vue')
}, {
  path: '/integral/apply',
  name: 'integralApply',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/integral/apply.vue')
}, {
  path: '/integral/success',
  name: 'integralSuccess',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/integral/success.vue')
}, {
  path: '/integral/user',
  name: 'integralUser',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/integral/user.vue')
}];
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_SRC,
  scrollBehavior: () => ({
    y: 0
  }),
  //切换路由后页面滚动到顶部
  routes: routes
});
import store from '@/store/index';
router.beforeEach(async (to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }

  // next();
  if (store.state.accessToken || !to.meta.requireAuth) {
    next();
  } else {
    next({
      path: '/login',
      query: {
        referrer: to.fullPath
      }
    });
  }
});
export default router;