import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/index.scss'; // 全局样式
import 'lib-flexible';
Vue.config.productionTip = false;
import request from './utils/request';
Vue.prototype.$request = request;
import ParentListContent from "@/components/parentListContent";
Vue.component("ParentListContent", ParentListContent);
import tabbarComp from '@/components/tabbar-comp';
Vue.component("tabbarComp", tabbarComp);
import ticketStepComp from '@/components/ticket-step-comp';
Vue.component("ticketStepComp", ticketStepComp);
import footBarBoxComp from '@/components/footBarBox-comp';
Vue.component("footBarBoxComp", footBarBoxComp);
import goodItemComp from '@/views/shop/components/good-items-comp';
Vue.component("goodItemComp", goodItemComp);
Vue.prototype.$toast = function ({
  message,
  type,
  duration
}) {
  _Toast({
    type: type || 'text',
    duration: duration || 3000,
    message: message || 'fail'
  });
};
Vue.use(_Image);
Vue.use(_Button);
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.prototype.$noScroll = function () {
  // var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden';
  // document.addEventListener('touchmove', mo, false)// 禁止页面滑动
};

//弹出框可以滑动
Vue.prototype.$canScroll = function () {
  // var mo = function (e) {
  //   e.preventDefault()
  // }
  document.body.style.overflow = ''; // 出现滚动条
  // document.removeEventListener('touchmove', mo, false)
};

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?f4556e59cfd1651d6be68fb4606c3c01";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');