let baseUrl='';
if(process.env.NODE_ENV === 'development'){
  // baseUrl='https://2761d932.r12.cpolar.top/hr'
  // baseUrl='https://leehgift.touchdot.top/hr'
  baseUrl='https://hongrungift.touchdot.top/hr'
  // baseUrl='https://hongrungift.touchdot.top/testhr'
}else if(process.env.NODE_ENV === 'production'){
  baseUrl='https://hongrungift.touchdot.top/hr'
  // baseUrl='https://leehgift.touchdot.top/hr'
  // baseUrl='https://hongrungift.touchdot.top/testhr'
}

export default {
  versionNumber:'2021-12-27-v1',
  baseUrl:baseUrl,
};
