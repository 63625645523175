var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-table"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished,
      "finished-text": "没有更多了"
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };