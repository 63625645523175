import "core-js/modules/es.array.push.js";
export default {
  name: "goodItems",
  components: {},
  props: {
    itemList: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    itemClick(item) {
      this.$router.push(`/goodDetail/${item.id}`);
    }
  }
};