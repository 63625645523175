var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tabbar-comp"
  }, [_c('div', {
    staticClass: "list allsurface"
  }, _vm._l(_vm.navList, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "li",
      class: {
        'cur': idx == _vm.index
      },
      on: {
        "click": function ($event) {
          return _vm.itemClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('img', {
      staticClass: "img1",
      attrs: {
        "src": item.icon
      }
    }), _c('img', {
      staticClass: "img2",
      attrs: {
        "src": item.selectIcon
      }
    })]), _c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0), _c('div', {
    staticClass: "kong-div allsurface"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };