import Vue from 'vue';
import Vuex from 'vuex';
import config from "@/utils/config";
Vue.use(Vuex);
let test = '';
// let test='test_'
const userInfoString = localStorage.getItem(test + "userInfo_2022_v1");
const giftDataString = sessionStorage.getItem(test + "giftData_2022_v1");
import request from '../utils/request';
export default new Vuex.Store({
  state: {
    baseUrl: config.baseUrl,
    userInfo: userInfoString ? JSON.parse(userInfoString) : {
      phone: null
    },
    accessToken: localStorage.getItem(test + "accessToken_2022") || '',
    giftData: giftDataString ? JSON.parse(giftDataString) : [],
    integralCurrency: {}
  },
  mutations: {
    setUserInfo(state, userInfo) {
      localStorage.setItem(test + "userInfo_2022_v1", JSON.stringify(userInfo));
      state.userInfo = userInfo;
    },
    setAccessToken(state, accessToken) {
      localStorage.setItem(test + "accessToken_2022", accessToken);
      state.accessToken = accessToken;
    },
    setGiftData(state, giftData) {
      sessionStorage.setItem(test + "giftData_2022_v1", JSON.stringify(giftData));
      state.giftData = giftData;
    }
  },
  actions: {
    //记录来源
    logOut: async ({
      commit
    }) => {
      commit('setAccessToken', '');
      commit('setUserInfo', {});
    },
    getIntegralCurrency: async ({
      state
    }) => {
      // if(state.accessToken&&state.userInfo.company){
      //   const result = await request({
      //     url: '/app/api/my/currency',
      //     method: 'GET',
      //   });
      //   if(result.code==0){
      //     state.integralCurrency=result.data
      //   }
      // }
    }
  },
  modules: {}
});