export default {
  props: {
    background: {
      type: String,
      default: '#ffffff'
    }
  },
  data() {
    return {
      opt: 1
    };
  },
  computed: {
    tabBarKong: function () {
      return this.$store.state.systemInfo.system.statusBarHeight - 15;
    }
  },
  async created() {
    if (this.bgColorFixed == true) {
      this.opt = 0;
    }
  },
  mounted() {},
  methods: {}
};