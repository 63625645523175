export default {
  name: "tabbar",
  components: {},
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navList: [{
        title: '我的礼品',
        url: '/index',
        icon: require('../assets/icon/tabbar-1.png'),
        selectIcon: require('../assets/icon/tabbar-1-cur.png')
      },
      // {
      //     title:'积分商城',
      //     url:'/shop',
      //     icon:require('../assets/icon/tabbar-2.png'),
      //     selectIcon:require('../assets/icon/tabbar-2-cur.png'),
      // },
      {
        title: '个人中心',
        url: '/mine',
        icon: require('../assets/icon/tabbar-3.png'),
        selectIcon: require('../assets/icon/tabbar-3-cur.png')
      }]
    };
  },
  created() {},
  methods: {
    itemClick(item) {
      this.$router.replace(item.url);
    },
    confirmClick() {
      this.$emit('confirmClick', true);
    },
    cancelClick() {
      this.$emit('cancelClick', true);
    }
  }
};