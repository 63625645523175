<template>
    <div>
<!--      <div class="tip">-->
<!--        <img src="../assets/tip.png">-->
<!--      </div>-->
<!--      <div class="tip-div">-->
<!--        <div class="title">温馨提示</div>-->
<!--        <div class="detail">-->
<!--          <div>礼品兑换平台物流停发通知:</div>-->
<!--          <div style="text-indent: 2em">因春运期间快递时效无法保证，2023年1月11日起产生的礼品兑换订单我们将在2023年1月31日安排陆续发出，感谢您的理解和支持！</div>-->
<!--          <div style="text-indent: 2em">祝大家春节快乐，兔年大吉！</div>-->
<!--        </div>-->
<!--      </div>-->


      <div class="ticket-step-comp" >
        <img src="https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-12.png">
        <div class="now" :style="'left: '+index*25+'%'">当前</div>
      </div>
    </div>
</template>

<script>

    export default {
        name: "ticketStep",

        props: {
            index: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {

            }
        },
        created() {

        },
        methods: {


        },
    }
</script>

<style lang="scss" scoped>
.tip{
  width: 688px;
  margin: 0 auto;
  margin-bottom: 20px;
  img{
    width: 100%;
  }
}
.tip-div{
  width: 688px;
  margin: 0 auto;
  margin-bottom: 20px;
  background: #fff5e3;
  border: 2px solid #cebea4;
  padding-bottom: 40px;
  border-radius: 15px;
  .title{
    font-size: 30px;
    color: #a27e3b;
    text-align: center;
    font-weight: bold;
    padding: 40px 0;
  }
  .detail{
    font-size: 28px;
    color: #b9935c;
    line-height: 42px;
    padding: 0 30px;
  }
}

    .ticket-step-comp{
        width: 688px;
        height: 304px;
        border-radius: 20px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
        .now{
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #FFFFFF;
            position: absolute;
            left: 0;
            top: 240px;
            width: 25%;
            text-align: center;
        }
    }

</style>
