import "vant/es/list/style";
import _List from "vant/es/list";
export default {
  props: {
    pageSize: {
      default: 20
    }
  },
  components: {
    [_List.name]: _List
  },
  data() {
    return {
      loading: false,
      finished: false,
      totalPage: 1,
      pageNumber: 1
    };
  },
  mounted() {
    this.reload();
  },
  methods: {
    async onLoad() {
      console.log('onReachBottom', '触底加载');
      console.log('onReachBottom', this.finished);
      if (this.pageNumber >= this.totalPage) this.finished = true;
      if (this.finished == true) {
        return false;
      }
      this.loading = true;
      this.pageNumber += 1;
      this.loadData('add');
      // const options = this.optionsData('frist'),
      //     result = await this.handleData(options);
      // // this.loadInitData(result, 'add');
      // this.$emit('loadInitData', result, 'add');
    },

    // 请求方法
    async reload() {
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.loadData('set');
      // const options = this.optionsData('next'),
      //     result = await this.handleData(options);
      // console.log('load -> options', options);
      // this.$emit('loadInitData', result, 'set');
    },

    loadData: function (type) {
      let that = this;
      this.$emit('onDataLoad', {
        pageNow: this.pageNumber,
        pageSize: this.pageSize
      }, function (results) {
        console.log(results);
        that.totalPage = results.data.page.totalPageCount;
        if (that.pageNumber == that.totalPage) {
          that.finished = true;
        }
        if (results.code == 0) {
          that.$emit('pushData', results.data.list, type);
        }
        that.loading = false;
      });
    },
    // 请求数据方法
    async handleData(options) {
      console.log('handleData -> options', options);
      const result = await this.$request({
        method: options.method,
        url: `${options.url}?pageNow=${this.pageNumber}&pageSize=${this.pageSize}`,
        data: options.data || {}
      });
      if (result.data.page.totalPageCount <= this.pageNumber) {
        // uni.showToast({
        //     title:'没有更多了',
        //     icon:'none'
        // });
        this.finished = true;
      }
      this.pageLoading = false;
      return result.data;
    }
  }
};