import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import config from '@/utils/config';

// create an axios instance
const service = axios.create({
  baseURL: config.baseUrl,
  timeout: 30000 // 请求超时
});

// request interceptor
service.interceptors.request.use(config => {
  // do something before request is sent
  if (store.state.accessToken) {
    // 让每个请求携带 token
    config.headers['token'] = store.state.accessToken;
  }
  return config;
}, error => {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  const res = response.data;
  console.log(response);
  // 如果状态码不是200，则判断为错误
  if (res.code == 0) {
    return res;
  } else if (res.code == -403) {
    _Toast('登陆已过期，请重新登陆');
    store.commit('setAccessToken', '');
    router.replace('/login');
    // window.location.href = '/'
    return Promise.reject(res);
  } else if (res.code == 500) {
    if (res.msg) {
      _Toast(res.msg || 'error');
    }
    return res;
    // return Promise.reject(res.msg || 'error')
  } else {
    return res;
  }
}, error => {
  console.log('请求失败' + error); // for debug
  let data = error.response.data;
  if (data.code == -403) {
    _Toast('登陆已过期，请重新登陆');
    store.commit('setAccessToken', '');
    router.replace('/login');
  } else {
    _Toast('系统异常');
  }
  return Promise.reject(error);
});
export default service;