var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.itemClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "cover"
    }, [_c('van-image', {
      attrs: {
        "width": "100%",
        "height": "100%",
        "fit": "cover",
        "src": item.cover
      }
    })], 1), _c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "price"
    }, [_vm._v(_vm._s(item.integral ? `${item.integral}积分` : '') + _vm._s(item.integral && item.price ? '+' : '') + _vm._s(item.price ? `${item.price}元` : ''))]), _c('div', {
      staticClass: "tag"
    }, [_vm._v("积分+现金兑换")])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };