<template>
  <div id="app">
    <router-view/>


    <div class="cus" v-if="!getHiddenHead()">
      <a href="tel:15361811997"></a>
<!--      <img src="./assets/icon/icon-9.png">-->
    </div>
  </div>
</template>

<script>

  export default {
    name: 'app',
    data() {
      return {
        hiddenHead:false,

      }

    },

    async created() {
      this.$store.dispatch('getIntegralCurrency')


    },

    methods: {

      getHiddenHead() {
        this.hiddenHead =["/login"].includes(this.$route.path)
                ? true
                : false;
        // return this.hiddenHead;
        return false;
      },
    }
  }
</script>

<style lang="scss">
.wrap{
  max-width: 750px;
  margin: 0 auto;
}


  /*全局公共樣式*/
  .allsurface{
    box-sizing: content-box !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
  }

  .cus{
    width: 90px;
    height:90px;
    background: url("./assets/icon/icon-9.png") center no-repeat rgba(199, 12, 12, 0.3);
    background-size: 70%;
    position: fixed;
    right: 10px;
    bottom: 15%;
    z-index: 999;
    border-radius: 100px;
    a{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

</style>
