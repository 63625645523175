var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "foot-bar-box-comp"
  }, [_c('div', {
    staticClass: "foot-bar-box allsurface"
  }, [_c('div', [_vm._t("default")], 2)]), _c('div', {
    staticClass: "kong",
    attrs: {
      "allsurface": ""
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };